import { Box, debounce } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Element, animateScroll as scroll } from "react-scroll";

type FullPageScrollProps = {
  children: React.ReactElement[];
};

function FullPageScroll(props: FullPageScrollProps) {
  const { children } = props;
  const touchPos = useRef<number>(0);

  const getElementTopPosition = useCallback((index: number) => {
    const currEle = document.getElementById(`scroll-child1-${index}`);
    const elementPos = currEle?.getBoundingClientRect().top;
    if (!elementPos) return 0;
    return elementPos;
  }, []);

  const getVisibleChildrenIndex = useCallback(
    (scrollTop: boolean) => {
      const windowYPos = window.scrollY;
      for (let i = 0; i < children.length; i += 1) {
        const currEle = document.getElementById(`scroll-child1-${i}`);
        if (!currEle) return 0;
        const offset = scrollTop ? currEle.offsetTop : currEle.offsetTop + currEle.offsetHeight;
        if (offset >= windowYPos) return i;
      }
      return 0;
    },
    [children.length],
  );

  const scrollNext = useCallback(() => {
    const curentIndex = getVisibleChildrenIndex(false);
    if (curentIndex >= children.length) return;
    const elementPos = getElementTopPosition(curentIndex + 1);
    if (!elementPos) return;
    scroll.scrollMore(elementPos, {
      duration: 800,
    });
  }, [children.length, getElementTopPosition, getVisibleChildrenIndex]);

  const scrollPrevious = useCallback(() => {
    const curentIndex = getVisibleChildrenIndex(true);
    if (curentIndex <= 0) return;
    const elementPos = getElementTopPosition(curentIndex - 1);
    if (!elementPos) return;
    if (curentIndex - 1 === 0) {
      scroll.scrollTo(0);
    } else {
      scroll.scrollMore(elementPos, {
        duration: 800,
      });
    }
  }, [getElementTopPosition, getVisibleChildrenIndex]);

  const onWheelDebounced = useMemo(
    () =>
      debounce((event: WheelEvent) => {
        event.preventDefault();
        let delta = 0;

        // if (event.wheelDelta) {
        //   delta = event.wheelDelta / 120;
        //   if (window.opera) {
        //     delta = -delta;
        //   }
        // } else
        if (event.detail) {
          delta = -event.detail / 3;
        } else if (event.deltaY) {
          delta = -event.deltaY / 3;
        }

        if (delta) {
          if (delta < 0) {
            scrollNext();
          } else {
            scrollPrevious();
          }
        }
      }, 100),
    [scrollNext, scrollPrevious],
  );

  const onTouchDebounced = useMemo(
    () =>
      debounce((event: TouchEvent) => {
        event.preventDefault();
        const touchYDiff = touchPos.current - event.touches[0].clientY;
        if (touchYDiff) {
          if (touchYDiff > 0) {
            scrollNext();
          } else {
            scrollPrevious();
          }
        }
      }, 100),
    [scrollNext, scrollPrevious],
  );

  useEffect(() => {
    const onTouchStart = (ev: TouchEvent) => {
      touchPos.current = ev.changedTouches[0].clientY;
    };

    window.addEventListener("wheel", onWheelDebounced);
    document.addEventListener("touchmove", onTouchDebounced, false);
    document.addEventListener("touchstart", onTouchStart, false);

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      window.removeEventListener("wheel", onWheelDebounced);
      document.addEventListener("touchmove", onTouchDebounced, false);
      document.addEventListener("touchstart", onTouchStart, false);
    };
  }, [children.length, onTouchDebounced, onWheelDebounced, scrollNext, scrollPrevious]);

  return (
    <Box className="element" id="containerElement">
      {children.map((child, i, arr) => (
        <Element name={`scroll-child1-${i}`} key={`scroll-child1-${i}`} id={`scroll-child1-${i}`}>
          {React.cloneElement(child, {
            isFirst: i === 0,
            isLast: arr.length - 1 === i,
          })}
        </Element>
      ))}
    </Box>
  );
}

export default FullPageScroll;
