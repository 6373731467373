import { Box, Stack, Typography, styled } from "@mui/material";
import Image from "next/image";
import { roboto } from "@/utis/fonts";
import { CommunityProps } from "./types";

interface CommunityCardProps {
  content: CommunityProps;
}

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "20px",
  borderRadius: "16px",
  width: "100%",
  background: `${theme.palette.primary.main}`,
  boxSizing: "border-box",
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  lineHeight: 1,
  color: `${theme.palette.text.primary}`,
  fontFamily: `${roboto.style.fontFamily}`,
}));

const StyledSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: 1,
  color: `${theme.palette.text.primary}`,
  fontFamily: `${roboto.style.fontFamily}`,
  marginTop: `${theme.spacing(1.5)}`,
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.text.secondary}`,
  fontSize: "16px",
  fontFamily: `${roboto.style.fontFamily}`,
  marginTop: `${theme.spacing(1.5)}`,
}));

const StyleImage = styled(Image)(({ theme }) => ({
  width: "100%",
  height: "auto",
}));

export default function CommunityCard({ content }: CommunityCardProps) {
  const { title, subtitle, description, icon, image } = content;
  return (
    <StyledBox>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <StyledTitle>{title}</StyledTitle>
        <Box>
          <Image src={icon} alt={title} height={28} width={28} />
        </Box>
      </Stack>
      {subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
      <Box>
        <StyledDescription mb="12px">{description}</StyledDescription>
        {image && <StyleImage src={image} alt={title} width={500} height={300} style={{ width: "100%", height: "100%" }} />}
      </Box>
    </StyledBox>
  );
}
