import { useMemo } from "react";
import { Typography, Stack, Box, Button, useTheme, useMediaQuery } from "@mui/material";

import { Link } from "@/navigation";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { ICON_FILE_NAME_LIST } from "@/constants/IconsFileName";
import shuffleArray from "@/utis/shuffleArray";
import { FullPageSection } from "../FullPageScroll";
import { headerGradient } from "./common/headerGradient";
import RandomBetSitesImages from "../RandomBetSitesImages";

function LineBreak() {
  return <br />;
}

function Middles() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const graditentWithGradient = theme.palette.mode === "light" ? {} : headerGradient;

  const randomIconImages = useMemo(() => shuffleArray(ICON_FILE_NAME_LIST), []);
  const t = useTranslations("Home");

  return (
    <FullPageSection px="5%" pt="2%" className="section" display="flex" alignContent="center">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography
          variant="h1"
          fontWeight="bold"
          zIndex={2}
          sx={{
            textAlign: "center",
            ...graditentWithGradient,
          }}
        >
          {t("beat_the_odds")}
        </Typography>
        <Typography
          variant={isMobile ? "body1" : "h5"}
          component="h5"
          fontWeight="bold"
          zIndex={2}
          sx={{
            textAlign: "center",
          }}
          mt={1}
        >
          {t("secure_profit_with_middles")}
        </Typography>
        <Stack
          direction={isMobile ? "column" : "row"}
          display="flex"
          justifyContent="center"
          borderRadius={theme.borderRadius(1)}
          height={isMobile ? undefined : "auto"}
          zIndex={2}
          my={3}
        >
          <Stack
            px={5}
            pt={5}
            sx={{
              borderRadius: theme.borderRadius(2),
              pb: 5,
              background: "rgba(58, 98, 197, 0.1)",
            }}
            display="flex"
            flexDirection={isMobile ? "column-reverse" : "row"}
          >
            {!isMobile && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="relative"
                maxWidth={isSmallScreen ? 150 : 270}
                width="100%"
                mt={isMobile ? 2 : 0}
              >
                <Image
                  alt="mascot explaining"
                  src="/images/mascotExplaining.png"
                  width={isSmallScreen ? 150 : 270} // Natural width of the image
                  height={300} // Natural height of the image
                  layout="responsive"
                  objectFit="contain"
                />
              </Box>
            )}
            <Box ml={isMobile ? 0 : 4}>
              <Typography mx="auto" fontSize={isSmallScreen ? 12 : undefined}>
                <b>{t("what_are_middles")}</b>
                <br />
                {t("what_are_middles_answer")}
                <br />
                <br />
                <b>{t("how_does_it_work")}</b>
                <br />
                {t("middles_how_does_it_work_answer")}
                <br />
                <br />
                <Box display="flex" justifyContent="space-between">
                  <Typography fontSize={isSmallScreen ? 12 : undefined}>
                    <b>{t("why_opt_for_middles_betting")}</b>
                    <br />
                    {t.rich("why_opt_for_middles_betting_answer", {
                      br: LineBreak,
                    })}
                  </Typography>
                  <Box display="flex" justifyContent="center" mt={2} alignItems="end">
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "#3A62C5",
                        borderRadius: theme.borderRadius(2),
                        height: isSmallScreen ? 40 : 50,
                        px: 4,
                        color: "#ECECEC",
                        fontWeight: 900,
                      }}
                    >
                      <Link style={{ textDecoration: "none", color: "white" }} href="/middles">
                        {t("try_it_free")}
                      </Link>
                    </Button>
                  </Box>
                </Box>
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <RandomBetSitesImages randomIconImages={randomIconImages} />
      </Box>
    </FullPageSection>
  );
}
export default Middles;
