import { Box, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { useState, useEffect } from "react";
import { karantina, roboto } from "@/utis/fonts";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper as SwiperType } from "swiper/types";
import { DataRow } from "./common/types";

import "swiper/css";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  background: theme.palette.customBackground.cards,
  padding: `${theme.spacing(15)} ${theme.spacing(7)}`,
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(6)} ${theme.spacing(2.5)}`,
    overflow: "hidden",
  },
}));

const StyledWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  maxWidth: "1200px",
  margin: "0 auto",
  columnGap: `${theme.spacing(16)}`,
  [theme.breakpoints.down("lg")]: {
    columnGap: `${theme.spacing(10)}`,
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const StyledImagesWrapper = styled(Box)(({ theme }) => ({
  flex: "1 0",
  maxWidth: "596px",
  position: "relative",
  "& .swiper": {
    height: "100%",
  },
  "& .swiper-slide": {
    opacity: "0 !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  "& .swiper-slide-visible": {
    opacity: "1 !important",
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "400px",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: `${theme.spacing(6)}`,
    maxWidth: "unset",
    "& .swiper": {
      height: "unset",
    },
  },
}));

const StyledTextWrapper = styled(Stack)(({ theme }) => ({
  flex: "1 0",
  justifyContent: "center",
  position: "sticky",
  top: 0,
  [theme.breakpoints.down("lg")]: {
    flex: "0 0 400px",
  },
  [theme.breakpoints.down("md")]: {
    flex: "1 0",
    minHeight: "unset",
    "& .swiper": {
      margin: `0 -${theme.spacing(3)}`,
      padding: `0 ${theme.spacing(3)}`,
      overflow: "visible",
    },
    "& .swiper-slide": {
      width: "fit-content",
    },
    "& .swiper-slide-active > div": {
      background: theme.palette.basic,
      color: theme.palette.customBackground.cards,
      borderColor: theme.palette.basic,
    },
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: `${theme.spacing(10)}`,
  lineHeight: 1,
  textTransform: "uppercase",
  fontWeight: "bold",
  fontFamily: `${karantina.style.fontFamily}`,
  [theme.breakpoints.down("md")]: {
    flexWrap: "nowrap",
    fontSize: `${theme.spacing(7)}`,
    textAlign: "center",
  },
}));

const StyledDesc = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  lineHeight: "28px",
  color: "#7e7e7e",
  marginTop: `${theme.spacing(3)}`,
  fontFamily: `${roboto.style.fontFamily}`,
  [theme.breakpoints.down("md")]: {
    flexWrap: "nowrap",
    fontSize: "14px",
    textAlign: "center",
    marginTop: `${theme.spacing(2)}`,
  },
}));

const StyledDataRowsGrid = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: "-12px",
  marginLeft: "-12px",
  [theme.breakpoints.down("md")]: {
    flexWrap: "nowrap",
  },
}));

const StyledDataRowItem = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.custom}`,
  borderRadius: "12px",
  padding: "12px 24px",
  fontSize: "18px",
  lineHeight: "28px",
  color: theme.palette.customBackground.custom,
  marginTop: "12px",
  marginLeft: "12px",
  cursor: "pointer",
  transition: "all .3s ease-in-out",
  whiteSpace: "nowrap",
  "&.active": {
    background: theme.palette.basic,
    color: theme.palette.customBackground.cards,
    borderColor: theme.palette.basic,
  },

  [theme.breakpoints.down("md")]: {
    padding: "12px 16px",
    fontSize: "14px",
    lineHeight: "20px",
    margin: 0,
  },
}));

const StyledDataItems = styled(Box)(({ theme }) => ({
  marginTop: `${theme.spacing(6)}`,
  [theme.breakpoints.down("md")]: {
    marginTop: `${theme.spacing(4)}`,
  },
}));

const StyledImage = styled(Image)(({ theme }) => ({
  width: "100%",
  maxWidth: "550px",
  display: "block",
  margin: "0 auto",
  height: "auto",
  [theme.breakpoints.down("md")]: {
    maxWidth: "400px",
  },
}));

function DataRows() {
  const theme = useTheme();
  const t = useTranslations("Home");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  const [imageSwiper, setImageSwiper] = useState<SwiperType | null>(null);

  useEffect(() => {
    if (imageSwiper) {
      if (thumbsSwiper) {
        thumbsSwiper.on("activeIndexChange", () => {
          imageSwiper.slideTo(thumbsSwiper.activeIndex);
        });
      }
      imageSwiper.on("activeIndexChange", () => {
        setActiveIndex(imageSwiper.activeIndex);
        if (thumbsSwiper) {
          thumbsSwiper?.slideTo(imageSwiper.activeIndex);
        }
      });
      imageSwiper.autoplay.start();
    }
  }, [activeIndex, imageSwiper, thumbsSwiper]);

  const handleClick = (index: number) => {
    imageSwiper?.slideTo(index);
    setActiveIndex(index);
  };

  const title = t("row_data_title");
  const description = t("row_data_desc");
  const rowData = t.raw("card_rows");

  return (
    <StyledBox>
      <StyledWrapper>
        <StyledTextWrapper>
          <StyledTitle>{title}</StyledTitle>
          <StyledDesc>{description}</StyledDesc>
          <StyledDataItems>
            {isMobile ? (
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={12}
                slidesPerView="auto"
                freeMode
                centeredSlides
                slideToClickedSlide
              >
                {rowData.map((dataRow: DataRow, index: number) => (
                  <SwiperSlide key={index}>
                    <StyledDataRowItem>{dataRow.title}</StyledDataRowItem>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <StyledDataRowsGrid>
                {rowData.map((dataRow: DataRow, index: number) => (
                  <StyledDataRowItem
                    key={index}
                    className={index === activeIndex ? "active" : ""}
                    onMouseOver={() => handleClick(index)}
                  >
                    {dataRow.title}
                  </StyledDataRowItem>
                ))}
              </StyledDataRowsGrid>
            )}
          </StyledDataItems>
        </StyledTextWrapper>
        <StyledImagesWrapper>
          <Swiper
            onSwiper={(swiper) => {
              setImageSwiper(swiper);
              swiper.autoplay.start();
            }}
            effect="fade"
            slidesPerView={1}
            autoplay={{ delay: 5000 }}
            centeredSlides
            allowTouchMove={false}
            modules={[EffectFade, Autoplay]}
          >
            {rowData.map((dataRow: DataRow, index: number) => (
              <SwiperSlide key={index}>
                <StyledImage src={dataRow.imageUrl} alt={dataRow.title} width={800} height={800} />
              </SwiperSlide>
            ))}
          </Swiper>
        </StyledImagesWrapper>
      </StyledWrapper>
    </StyledBox>
  );
}

export default DataRows;
