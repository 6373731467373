import React from "react";
import { SvgIconProps } from "@mui/material";

function CurvyLines(props: SvgIconProps) {
  return (
    <svg width={1728} height={662} viewBox="0 0 1728 662" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1924 -47C1507.28 89.3913 1782.19 642.862 1113.01 650.79C443.822 658.719 -6.55526 307.271 -509 287.91"
        stroke="url(#paint0_linear_0_1)"
        strokeOpacity={0.39}
        strokeWidth={2}
      />
      <path
        d="M2780.26 -56.1948C2093.39 21.8282 1925.67 639.894 1000.96 642.794C76.2863 645.695 -227.418 266.51 -778.305 245.354"
        stroke="url(#paint1_linear_0_1)"
        strokeOpacity={0.39}
      />
      <path
        d="M1925 -119C1343.64 9.41788 1415.53 639.397 672.5 659.98C-70.5295 680.563 -185.937 382.949 -580 366.111"
        stroke="url(#paint2_linear_0_1)"
        strokeOpacity={0.39}
        strokeWidth={2}
      />
      <g filter="url(#filter0_d_0_1)">
        <path
          d="M2654.46 -153C2279.97 -139.147 2060.77 113.169 1438.3 114.097C815.821 115.026 830.243 6.09357 222.162 6.10131C-385.919 6.09357 -676.833 112.96 -994 114.097"
          stroke="url(#paint3_linear_0_1)"
          strokeOpacity={0.39}
          strokeWidth={2}
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_0_1"
          x={-998.004}
          y={-153.999}
          width={3656.5}
          height={277.103}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_0_1"
          x1={-121.954}
          y1={508.206}
          x2={1753.74}
          y2={279.409}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A62C5" stopOpacity={0} />
          <stop offset={0.0001} stopColor="#223666" stopOpacity={0.4} />
          <stop offset={1} stopColor="#3A62C5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_1"
          x1={-212.202}
          y1={499.873}
          x2={2486.43}
          y2={19.1549}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A62C5" stopOpacity={0} />
          <stop offset={0.0001} stopColor="#223666" stopOpacity={0.4} />
          <stop offset={1} stopColor="#3A62C5" />
        </linearGradient>
        <linearGradient id="paint2_linear_0_1" x1={-181.5} y1={501.5} x2={1754} y2={284} gradientUnits="userSpaceOnUse">
          <stop stopColor="#3A62C5" stopOpacity={0} />
          <stop offset={0.0001} stopColor="#223666" stopOpacity={0.4} />
          <stop offset={1} stopColor="#3A62C5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_1"
          x1={-413.596}
          y1={59.4841}
          x2={1910.17}
          y2={-1051.16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3A62C5" stopOpacity={0} />
          <stop offset={0.0001} stopColor="#223666" stopOpacity={0.4} />
          <stop offset={1} stopColor="#3A62C5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CurvyLines;
