import { Box, Stack, Typography, useMediaQuery, useTheme, Button } from "@mui/material";
import { useTranslations } from "next-intl";
import { FullPageSection } from "../FullPageScroll";
import CurvyLines from "../Icons/CurvyLines";
import Testimonual from "../Testimonual";
import { headerGradient } from "./common/headerGradient";

function JustWinning({ handleOpenLoginPopup }: { handleOpenLoginPopup: () => void }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const graditentWithGradient = theme.palette.mode === "light" ? {} : headerGradient;
  const t = useTranslations("Home");
  return (
    <FullPageSection px="5%" className="section">
      <Box
        position="absolute"
        left={isMobile ? 0 : "-40%"}
        top="-80%"
        sx={{
          transform: `rotate(-43deg) scale(${isMobile ? 1.3 : 0.9})`,
        }}
      >
        <CurvyLines width="100vw" height="100vh" />
      </Box>

      <Stack display="flex" height="90vh" justifyContent="center">
        <Typography
          fontWeight="bold"
          variant="h1"
          fontSize={isMobile ? 26 : undefined}
          textAlign="center"
          sx={graditentWithGradient}
        >
          {t("just_winning")}
        </Typography>
        {!isMobile && (
          <Typography
            variant={isMobile ? "h2" : "body1"}
            color="text.secondary"
            maxWidth={900}
            textAlign="center"
            mx="auto"
            mt={1}
          >
            {t("just_winning_detail")}
          </Typography>
        )}
        <Box display="flex" justifyContent="center" mt={3}>
          <Button
            variant="contained"
            size="large"
            sx={{
              backgroundColor: "#3A62C5",
              mr: "-11px",
              borderRadius: theme.borderRadius(1),
              px: 4,
              ml: 1,
              color: "#ECECEC",
              fontWeight: 900,
            }}
            onClick={handleOpenLoginPopup}
          >
            {t("signup_now")}
          </Button>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection={isMobile ? "column" : "row"}>
          <Testimonual id="1779178902373421232" />
        </Box>
      </Stack>
    </FullPageSection>
  );
}

export default JustWinning;
