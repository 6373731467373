import { Box, Typography, styled } from "@mui/material";
import Image from "next/image";
import { karantina, roboto } from "@/utis/fonts";
import { ToolContentType } from "./types";

interface ToolCardProps {
  content: ToolContentType;
}

const StyledWrapper = styled(Box)(({ theme }) => ({
  background: `linear-gradient(to bottom, ${theme.palette.background.default}, transparent)`,
  padding: "20px",
  borderRadius: "16px",
  width: "100%",
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "36px",
  color: theme.palette.text.primary,
  fontFamily: `${karantina.style.fontFamily}`,
  fontWeight: "bold",
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: theme.palette.text.secondary,
  fontFamily: `${roboto.style.fontFamily}`,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "16px",
  borderRadius: "12px",
  backgroundColor: theme.palette.customBackground.planCard,
  width: "64px",
  height: "64px",
  marginLeft: "auto",
  marginTop: "56px",
}));

export default function ToolCard({ content }: ToolCardProps) {
  return (
    <StyledWrapper>
      <StyledTitle>{content.title}</StyledTitle>
      <StyledDescription>{content.description}</StyledDescription>
      <StyledBox>
        <Image src={content.icon} alt={content.title} height={32} width={32} />
      </StyledBox>
    </StyledWrapper>
  );
}
