import { useTheme } from "@mui/material";
import "./styles.css";
import { Tweet } from "react-tweet";

function Testimonual({ id }: { id: string }) {
  const theme = useTheme();
  const className = theme.palette.mode === "light" ? "light" : "dark";

  return (
    <div style={{ width: "300px" }} className={className}>
      <Tweet id={id} />
    </div>
  );
}

export default Testimonual;
