import React, { useEffect, useRef } from "react";
import { Box, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BetMarketInfo, Leagues } from "@/store/constants";
import formatLeagueName from "@/utis/formatLeagueName";
import { BetMarketSiteBetSiteType } from "@/store/sportsBook";
import StyledImage from "../StyledComponents/StyledImage";
import SportIcon from "../Icons/SportIcon";

function ScrollableGrid({
  data,
  header,
  scrollingSpeed = 20,
}: {
  data: BetMarketInfo[] | BetMarketSiteBetSiteType[] | Leagues[];
  header: string;
  scrollingSpeed?: number;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const scrollRef = useRef<HTMLDivElement>(null);

  const getName = (item: BetMarketInfo | BetMarketSiteBetSiteType | Leagues): React.ReactNode => {
    if ("displayName" in item && typeof item.displayName === "string") {
      return item.displayName;
    }
    if ("iconImage" in item && "name" in item) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <StyledImage src={item.iconImage} alt={item.name} width={isMobile ? 30 : 40} height={isMobile ? 30 : 40} />
          {isSmallScreen ? item.abbreviation : item.name}
        </Box>
      );
    }
    if ("id" in item) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <SportIcon sportType={item.sportId} width={isMobile ? 20 : 40} height={isMobile ? 20 : 40} />
          {formatLeagueName(item.id)}
        </Box>
      );
    }
    return "";
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    let scrollStep = 1; // Positive for down, negative for up
    const intervalTime = scrollingSpeed; // Time between each scroll step in milliseconds

    const autoScroll = () => {
      if (scrollElement) {
        if (
          (scrollStep > 0 && scrollElement.scrollTop < scrollElement.scrollHeight - scrollElement.clientHeight) ||
          (scrollStep < 0 && scrollElement.scrollTop > 0)
        ) {
          // Scroll down or up
          scrollElement.scrollTop += scrollStep;
        } else {
          // Reverse direction at top or bottom
          scrollStep = -scrollStep;
        }
      }
    };

    const scrollInterval = setInterval(autoScroll, intervalTime);

    return () => clearInterval(scrollInterval);
  }, [scrollingSpeed]);

  const getHeight = () => {
    if (isMobile) {
      return 130;
    }

    if (isSmallScreen) {
      return 300;
    }

    return 400;
  };

  return (
    <Paper
      ref={scrollRef}
      style={{
        height: getHeight(),
        width: isMobile ? 300 : 400,
        overflow: "hidden",
        zIndex: 2,
        backgroundColor: "rgba(9, 19, 30, 0.8)",
        borderRadius: 20,
      }}
      onScroll={(e) => {
        e.stopPropagation();
      }}
      onWheel={(e) => {
        e.stopPropagation();
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          position: "sticky", // Making the header sticky
          top: 0, // Stick to the top of the parent element
          zIndex: 2,
          py: 2,
          backgroundImage: "linear-gradient(90deg, rgba(23, 36, 68, 0.7) 0%, rgba(9, 19, 30, 0.8) 100%)",
        }}
      >
        <Typography
          fontWeight="bold"
          variant="h5"
          fontSize={isMobile ? 14 : 20}
          textAlign="center"
          sx={{
            backgroundImage: "linear-gradient(to right, #3A62C5, #FFFFFF)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {header}
        </Typography>
      </Box>
      <Grid container spacing={isMobile ? 4 : 2} padding={2} zIndex={2}>
        {data.map((item, index) => (
          <Grid item xs={isMobile ? 6 : 4} md={isMobile ? 6 : undefined} key={index}>
            <Box
              height={isMobile ? 60 : 92}
              width="100%"
              p={1}
              display="flex"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              borderRadius={theme.borderRadius(1)}
              boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
            >
              <Typography fontSize={12} color="#fff" sx={{ wordBreak: "break-word" }}>
                {getName(item)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

export default ScrollableGrid;
