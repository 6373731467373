import StyledImage from "@/components/StyledComponents/StyledImage";
import { Box, styled } from "@mui/material";

function randomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + min)) + min;
}

const AnimatedImageContainer = styled(Box)<{ delay: number }>(
  ({ delay, theme }) => `
      margin-top: ${randomNumber(10, 80)}%;
      border-radius: 50%;
      background: ${theme.palette.mode === "light" ? "" : "#0e1827"};
      width: 96px;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
    
      animation-delay: ${delay}s;
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    
      @keyframes floating {
        0% { transform: translate(0,  0px); }
        50%  { transform: translate(${randomNumber(-5, 5)}px, ${randomNumber(-5, 5)}px); }
        50%  { transform: translate(${randomNumber(-5, 5)}px, ${randomNumber(-5, 5)}px); }
        100%   { transform: translate(0, -0px); }    
      }
    `,
);

function BetIconsRandomPosition({ src, index }: { src: string; index: number }) {
  return (
    <Box>
      <AnimatedImageContainer delay={index}>
        <StyledImage src={src} alt="" width={48} height={48} />
      </AnimatedImageContainer>
    </Box>
  );
}

export default BetIconsRandomPosition;
