import React, { ComponentProps, useEffect, useState } from "react";
import Image from "next/image";
import { styled } from "@mui/material";

const StyledImage = styled(Image)(() => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));

type ImageChangerProps = { srcList: string[] } & Omit<ComponentProps<typeof Image>, "src">;

function ImageChanger({ srcList, ...restProps }: ImageChangerProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const changeImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % srcList.length);
    };

    const intervalId = setInterval(changeImage, 1500);

    return () => clearInterval(intervalId);
  }, [srcList]);

  return <StyledImage src={srcList[currentImageIndex]} {...restProps} />;
}

export default ImageChanger;
