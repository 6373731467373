import React from "react";
import { karantina } from "@/utis/fonts";
import { Box, Stack, Typography, styled, useTheme } from "@mui/material";
import { useTranslations } from "next-intl";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";

import CommunityCard from "./common/CommunityCard";
import { CommunityProps } from "./common/types";

const StyledContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  background: `${theme.palette.customBackground.cards}`,
  overflow: "hidden",
}));

const StyledInnerWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(15)} ${theme.spacing(7)}`,
  maxWidth: "1312px",
  margin: "0 auto",
  "& .swiper": {
    overflow: "visible",
  },
  "& .swiper-slide": {
    width: "282px",
  },
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(6)} ${theme.spacing(2.5)}`,
    "& .swiper": {
      marginTop: `${theme.spacing(3)}`,
    },
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "80px",
  lineHeight: 1,
  textAlign: "center",
  textTransform: "uppercase",
  fontWeight: "bold",
  fontFamily: `${karantina.style.fontFamily}`,
  [theme.breakpoints.down("md")]: {
    fontSize: "56px",
  },
}));

const StyledGradientBox = styled(Box)(
  ({ theme }) => `
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 3;
    height: 50%;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, ${theme.palette.customBackground.cards});
  `,
);

const StyleSlideItem = styled(Stack)(({ theme }) => ({
  position: "relative",
  gap: `${theme.spacing(2)}`,
  [theme.breakpoints.down("md")]: {
    "&.down": {
      marginTop: `${theme.spacing(3)}`,
    },
  },
}));

function Community() {
  const t = useTranslations("Home");
  const communityContent1 = t.raw("community_first");
  const communityContent2 = t.raw("community_second");
  const communityContent3 = t.raw("community_third");
  const communityContent4 = t.raw("community_fourth");
  const communityTitle1 = t("community_title_first");
  const communityTitle2 = t("community_title_second");
  const theme = useTheme();
  return (
    <StyledContainer>
      <StyledInnerWrapper>
        <StyledTitle>{communityTitle1}</StyledTitle>
        <StyledTitle sx={{ color: theme.palette.customBackground.custom }} mb={1.5}>
          {communityTitle2}
        </StyledTitle>
        <Swiper slidesPerView="auto" spaceBetween={24} freeMode modules={[FreeMode]}>
          <SwiperSlide>
            <StyleSlideItem>
              {communityContent1.map((item: CommunityProps, index: number) => (
                <CommunityCard content={item} key={index} />
              ))}
              <StyledGradientBox />
            </StyleSlideItem>
          </SwiperSlide>
          <SwiperSlide>
            <StyleSlideItem className="down">
              {communityContent2.map((item: CommunityProps, index: number) => (
                <CommunityCard content={item} key={index} />
              ))}
              <StyledGradientBox />
            </StyleSlideItem>
          </SwiperSlide>
          <SwiperSlide>
            <StyleSlideItem className="down">
              {communityContent3.map((item: CommunityProps, index: number) => (
                <CommunityCard content={item} key={index} />
              ))}
              <StyledGradientBox />
            </StyleSlideItem>
          </SwiperSlide>
          <SwiperSlide>
            <StyleSlideItem>
              {communityContent4.map((item: CommunityProps, index: number) => (
                <CommunityCard content={item} key={index} />
              ))}
              <StyledGradientBox />
            </StyleSlideItem>
          </SwiperSlide>
        </Swiper>
      </StyledInnerWrapper>
    </StyledContainer>
  );
}

export default Community;
