import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { useTranslations } from "next-intl";

import { karantina } from "@/utis/fonts";
import AvailablePlans from "../AvailablePlans";

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "80px",
  lineHeight: 1,
  textTransform: "uppercase",
  padding: "0px",
  marginBottom: "60px",
  fontWeight: "bold",
  fontFamily: `${karantina.style.fontFamily}`,
  [theme.breakpoints.down("md")]: {
    fontSize: "54px",
  },
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(15)} ${theme.spacing(7)}`,
  position: "relative",
  background: `linear-gradient(to bottom, transparent, ${theme.palette.customBackground.cards})`,
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(6)} ${theme.spacing(3)}`,
  },
}));

function PlansAndPayment() {
  const t = useTranslations("Home");
  return (
    <StyledContainer className="section">
      <Box sx={{ maxWidth: "1200px" }} justifyContent="center" ml="auto" mr="auto" pb="96px">
        <StyledTitle>{t("plan_title")}</StyledTitle>
        <AvailablePlans hideTopBtn flexGrow={1} />
      </Box>
    </StyledContainer>
  );
}

export default PlansAndPayment;
