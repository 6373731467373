import { ICON_FILE_NAME_LIST } from "@/constants/IconsFileName";
import { Stack, Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import { useAppSelector } from "@/store/store";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import { useTranslations } from "next-intl";
import { Sports } from "../Common/Enums/SportsEnum";
import { FullPageSection } from "../FullPageScroll";
import BetTypesIcon from "../Icons/BetTypesIcon";
import SportIcon from "../Icons/SportIcon";
import ScrollableGrid from "../ScrollableGrid";
import { headerGradient } from "./common/headerGradient";

function OurData() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const graditentWithGradient = theme.palette.mode === "light" ? {} : headerGradient;

  const { leagues: leaguesList, betMarketInfo, betSites: sportbooks } = useAppSelector((state) => state.constantsReducer);

  const t = useTranslations("Home");

  return (
    <FullPageSection px="5%" pt="2%" className="section">
      <Stack height="90vh" display="flex" justifyContent="space-around">
        <Typography fontWeight="bold" variant="h1" textAlign="center" sx={graditentWithGradient} mb={2}>
          {t("insane_amount_of_data")}
        </Typography>
        <Box
          display="flex"
          justifyContent={isMobile ? "center" : "space-around"}
          alignItems={isMobile ? "center" : undefined}
          flexDirection={isMobile ? "column" : "row"}
          mt={2}
          gap={isMobile ? 5 : 2}
        >
          <ScrollableGrid data={sportbooks || []} header={t("bet_sites")} />
          <ScrollableGrid data={leaguesList || []} header={t("leagues")} scrollingSpeed={50} />
          <ScrollableGrid data={betMarketInfo || []} header={t("bet_types")} />
        </Box>
        <Stack
          direction="row"
          display="flex"
          zIndex={0}
          py={isMobile ? 1 : 2}
          px={2}
          justifyContent="space-around"
          borderRadius={theme.borderRadius(4)}
          mt={5}
          sx={{
            backgroundImage: "linear-gradient(89.94deg, #263E79 0.04%, rgba(11,22,35, 0.8) 98.96%)",
          }}
        >
          <Stack alignItems="center" display="flex" flexDirection="row">
            <DynamicFeedIcon
              sx={{
                width: isMobile ? 20 : 60,
                height: isMobile ? 20 : 60,
                color: "#ECECEC",
                marginRight: 2,
              }}
            />
            <Typography variant={isMobile ? "body1" : "h2"} fontWeight="bold" mt={1} color="#ECECEC">
              {ICON_FILE_NAME_LIST.length} {t("bet_sites")}
            </Typography>
          </Stack>
          <Stack display="flex" flexDirection="row" alignItems="center">
            <SportIcon
              sportType={Sports.FOOTBALL}
              sx={{
                width: isMobile ? 20 : 60,
                height: isMobile ? 20 : 60,
                color: "#ECECEC",
                marginRight: 2,
              }}
            />
            <Typography variant={isMobile ? "body1" : "h2"} fontWeight="bold" mt={1} color="#ECECEC">
              {leaguesList?.length} {t("leagues")}
            </Typography>
          </Stack>
          <Stack display="flex" flexDirection="row" alignItems="center">
            <BetTypesIcon
              sx={{
                width: isMobile ? 20 : 60,
                height: isMobile ? 20 : 60,
                mr: 2,
              }}
            />
            <Typography variant={isMobile ? "body1" : "h2"} fontWeight="bold" mt={1} color="#ECECEC">
              {betMarketInfo?.length} {t("bet_types")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </FullPageSection>
  );
}

export default OurData;
