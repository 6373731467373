import { Typography, Box, styled, Stack } from "@mui/material";
import { useTranslations } from "next-intl";

import { karantina } from "@/utis/fonts";
import ToolCard from "./common/ToolCard";
import { ToolContentType } from "./common/types";

const StyledContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.customBackground.cards,
}));

const StyledInnerWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(15)} ${theme.spacing(7)}`,
  maxWidth: "1312px",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(6)} ${theme.spacing(2.5)}`,
  },
}));

const StyledTextWrapper = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  zIndex: 2,
  textAlign: "center",
  fontSize: "80px",
  lineHeight: 1,
  textTransform: "uppercase",
  fontFamily: `${karantina.style.fontFamily}`,
  [theme.breakpoints.down("md")]: {
    fontSize: "56px",
  },
}));

const StyledGrid = styled(Stack)(({ theme }) => ({
  marginTop: `${theme.spacing(8)}`,
  flexDirection: "row",
  columnGap: `${theme.spacing(3)}`,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    marginTop: `${theme.spacing(6)}`,
    columnGap: 0,
    rowGap: `${theme.spacing(2)}`,
  },
}));

function OtherTools() {
  const t = useTranslations("Home");
  const toolContent = t.raw("tool_content");

  return (
    <StyledContainer className="section">
      <StyledInnerWrapper>
        <Box sx={{ maxWidth: "1200px", ml: "auto", mr: "auto" }}>
          <StyledTextWrapper variant="h1">{t("more_tools")}</StyledTextWrapper>
          <StyledGrid>
            {toolContent.map((item: ToolContentType, index: number) => (
              <ToolCard content={item} key={index} />
            ))}
          </StyledGrid>
        </Box>
      </StyledInnerWrapper>
    </StyledContainer>
  );
}
export default OtherTools;
