import { Stack, Typography, Box, IconButton, useTheme, useMediaQuery, Button, styled, Paper, InputBase } from "@mui/material";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { roboto } from "@/utis/fonts";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useCallback, useState } from "react";
import NavigationPanel from "../NavigationPanel";
import LoginPopup from "../Login/LoginPopup";
import { LoginType } from "../Login/LoginPopup/common";

const StyledWrapper = styled(Box)(
  () => `
    position: relative;
  `,
);

const StyledNavigationPanel = styled(NavigationPanel)`
  button {
    background-color: inherit !important;
  }
  a {
    background-color: inherit !important;
  }
`;

const StyledBody = styled(Stack)(
  () => `
    flex-direction: row;
    width: 100%;
  `,
);

const StyledContent = styled(Box)(
  ({ theme }) => `
    flex-grow: 1;
    padding-left: ${theme.spacing(5)};
    ${theme.breakpoints.down("md")} {
      padding-left: ${theme.spacing(2)};
    }
  `,
);

const StyledFadedBorder = styled(Box)(
  ({ theme }) => `
    width: 1px;
    position: relative;
    background: linear-gradient(to bottom, ${theme.palette.divider}, ${theme.palette.primary.main});
  `,
);

function Top() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const t = useTranslations("Home");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value.trim();
    setEmailAddress(email);
  };

  const openLoginPopup = useCallback(() => {
    setShowLoginPopup(true);
  }, []);

  const closeLoginPopup = useCallback(() => {
    setShowLoginPopup(false);
  }, []);

  return (
    <StyledWrapper>
      <StyledBody>
        <StyledNavigationPanel hideBorder />
        <StyledFadedBorder />
        <StyledContent>
          <Box mt={isMobile ? undefined : 8}>
            <Typography
              variant="karantinaHeader"
              style={{
                fontSize: isMobile ? "88px" : "128px",
              }}
            >
              {t("title")}
            </Typography>
          </Box>
          <Box position="relative">
            <Box zIndex={2} width="100%" height="100%">
              <Typography
                variant={isMobile ? "h2" : "body1"}
                component="h2"
                mt={2}
                style={{ marginBottom: "40px", fontFamily: `${roboto.style.fontFamily}` }}
              >
                <span style={{ color: theme.palette.text.secondary, fontSize: "20px" }}>{t("description")}</span>
              </Typography>
              <Paper
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: isMobile ? 343 : 450,
                  backgroundColor: theme.palette.customBackground.progress,
                  borderRadius: "12px",
                }}
              >
                <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <MailOutlineIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Email Address"
                  value={emailAddress}
                  onChange={handleEmailChange}
                  inputProps={{ "aria-label": "email address" }}
                />
                <Button
                  variant="contained"
                  size="large"
                  fullWidth={false}
                  color="secondary"
                  onClick={openLoginPopup}
                  sx={{
                    height: 60,
                    width: "fit-content",
                    background: theme.palette.text.primary,
                    px: 2,
                    fontWeight: 600,
                    color: theme.palette.basicReverse,
                    textTransform: "none",
                  }}
                >
                  <span className={roboto.className}> {t("signup_now")}</span>
                </Button>
                <LoginPopup open={showLoginPopup} onClose={closeLoginPopup} email={emailAddress} loginType={LoginType.SIGN_UP} />
              </Paper>
            </Box>
            <Image
              src={isMobile ? "/images/mobile_background.png" : "/images/background.png"}
              width={1600}
              height={1600}
              alt="mascot"
              style={{ width: "100%", height: "auto", zIndex: 1, marginTop: "50px", position: "relative" }}
            />
          </Box>
        </StyledContent>
      </StyledBody>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        width="100%"
        height="10%"
        sx={{
          background: `linear-gradient(to top, ${theme.palette.customBackground.cards}, transparent)`,
        }}
      />
    </StyledWrapper>
  );
}

export default Top;
