export const ICON_FILE_NAME_LIST: string[] = [
  "ballybet.webp",
  "bet365.webp",
  "bet99.webp",
  "betano.webp",
  "betjack.webp",
  "betobet.webp",
  "betonline.webp",
  "betplays.webp",
  "betregal.webp",
  "betrivers.webp",
  "betway_us.webp",
  "bluebet.webp",
  "bodog.webp",
  "bovada.webp",
  "caesars.webp",
  "clutchbet.webp",
  "coolbet.webp",
  "dexsport.webp",
  "draftkings.webp",
  "espnbet.webp",
  "fanatics.webp",
  "fanduel.webp",
  "fliff.webp",
  "hardrock.webp",
  "ladbrokes.webp",
  "lowvig.webp",
  "mgm.webp",
  "mybookie.webp",
  "overtimemarkets.webp",
  "partypoker.webp",
  "pinnacle.webp",
  "pointsbet.webp",
  "prolineplus.webp",
  "prophetexchange.webp",
  "ps3838.webp",
  "sportinteraction.webp",
  "sporttrade.webp",
  "sugarhouse.webp",
  "superbook.webp",
  "thescore.webp",
  "tipico.webp",
  "unibet.webp",
  "virginbet.webp",
  "wynnbet.webp",
];
