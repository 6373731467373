import { Box } from "@mui/material";
import React, { ComponentProps } from "react";

type FullPageSectionProps = {
  children: React.ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
} & ComponentProps<typeof Box>;

function FullPageSection({ children, isFirst, isLast, ...restProps }: FullPageSectionProps) {
  return (
    <Box
      style={{
        position: "relative",
        width: "100%",
      }}
      className="pagescroll"
      display="flex"
      flexDirection="column"
      {...restProps}
    >
      {children}
    </Box>
  );
}

export default FullPageSection;
