import { useEffect, useState } from "react";
import { Box, Typography, Stack, styled, useMediaQuery, useTheme } from "@mui/material";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { EffectFade } from "swiper/modules";

import { karantina, roboto } from "@/utis/fonts";
import { FILTER_TYPES } from "@/constants/FilterTypes";

import "swiper/css";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  background: theme.palette.customBackground.cards,
  padding: `${theme.spacing(12)} ${theme.spacing(7)}`,
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(6)} ${theme.spacing(2.5)}`,
    overflow: "hidden",
  },
}));

const StyledWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "row-reverse",
  justifyContent: "center",
  alignItems: "stretch",
  maxWidth: "1200px",
  margin: "0 auto",
  columnGap: `${theme.spacing(16)}`,
  [theme.breakpoints.down("lg")]: {
    columnGap: `${theme.spacing(10)}`,
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const StyledImagesWrapper = styled(Box)(({ theme }) => ({
  flex: "1 0",
  maxWidth: "596px",
  position: "relative",
  "& .swiper": {
    height: "100%",
  },
  "& .swiper-slide": {
    opacity: "0 !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  "& .swiper-slide-visible": {
    opacity: "1 !important",
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "400px",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: `${theme.spacing(6)}`,
    maxWidth: "unset",
    "& .swiper": {
      height: "unset",
    },
  },
}));

const StyledTextWrapper = styled(Stack)(({ theme }) => ({
  flex: "1 0",
  justifyContent: "center",
  position: "sticky",
  top: 0,
  [theme.breakpoints.down("lg")]: {
    flex: "0 0 400px",
  },
  [theme.breakpoints.down("md")]: {
    flex: "1 0",
    minHeight: "unset",
    "& .swiper": {
      margin: `0 -${theme.spacing(3)}`,
      padding: `0 ${theme.spacing(3)}`,
      overflow: "visible",
    },
    "& .swiper-slide": {
      width: "fit-content",
      position: "relative",
      "&:not(:last-of-type):after": {
        content: `""`,
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        width: "1px",
        background: `linear-gradient(to bottom, ${theme.palette.border.secondary}, ${theme.palette.border.custom})`,
      },
    },
    "& .swiper-slide-active": {
      "& svg": {
        fill: theme.palette.text.primary,
      },
      "& p": {
        color: theme.palette.text.primary,
      },
    },
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: `${theme.spacing(10)}`,
  lineHeight: 1,
  textTransform: "uppercase",
  fontWeight: "bold",
  fontFamily: `${karantina.style.fontFamily}`,
  [theme.breakpoints.down("md")]: {
    flexWrap: "nowrap",
    fontSize: `${theme.spacing(7)}`,
    textAlign: "center",
  },
}));

const StyledDesc = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  lineHeight: "28px",
  color: "#7e7e7e",
  marginTop: `${theme.spacing(3)}`,
  fontFamily: `${roboto.style.fontFamily}`,
  [theme.breakpoints.down("md")]: {
    flexWrap: "nowrap",
    fontSize: "14px",
    textAlign: "center",
    marginTop: `${theme.spacing(2)}`,
  },
}));

const StyledImage = styled(Image)(({ theme }) => ({
  width: "100%",
  maxWidth: "550px",
  display: "block",
  margin: "0 auto",
  height: "auto",
  [theme.breakpoints.down("md")]: {
    maxWidth: "400px",
  },
}));

const StyledFilterRow = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  columnGap: "12px",
  cursor: "pointer",
  position: "relative",
  "& p": {
    color: theme.palette.text.secondary,
    transition: "all .2s ease-in-out",
  },
  "& svg": {
    fill: theme.palette.text.secondary,
    transition: "all .2s ease-in-out",
  },
  padding: "20px 0",
  "&:not(:last-of-type):after": {
    content: `""`,
    display: "block",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "1px",
    background: `linear-gradient(to right, ${theme.palette.border.secondary}, ${theme.palette.border.custom})`,
  },
  "&.active": {
    "& svg": {
      fill: theme.palette.text.primary,
    },
    "& p": {
      color: theme.palette.text.primary,
    },
  },
  [theme.breakpoints.down("md")]: {
    padding: `0 ${theme.spacing(3)}`,
    "& p": {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
}));

const StyledDataItems = styled(Box)(({ theme }) => ({
  marginTop: `${theme.spacing(6)}`,
  [theme.breakpoints.down("md")]: {
    marginTop: `${theme.spacing(4)}`,
  },
}));

function Filters() {
  const [activeIndex, setActiveIndex] = useState(0);
  const theme = useTheme();
  const t = useTranslations("Home");
  const filters = t.raw("filters");
  const filterTitle = t("filter_title");
  const filterDescription = t("filter_desc");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  const [imageSwiper, setImageSwiper] = useState<SwiperType | null>(null);

  useEffect(() => {
    if (imageSwiper) {
      if (thumbsSwiper) {
        thumbsSwiper.on("activeIndexChange", () => {
          imageSwiper.slideTo(thumbsSwiper.activeIndex);
        });
      }
      imageSwiper.on("activeIndexChange", () => {
        setActiveIndex(imageSwiper.activeIndex);
        if (thumbsSwiper) {
          thumbsSwiper?.slideTo(imageSwiper.activeIndex);
        }
      });
    }
  }, [activeIndex, imageSwiper, thumbsSwiper]);

  const handleClick = (index: number) => {
    imageSwiper?.slideTo(index);
    setActiveIndex(index);
  };

  return (
    <StyledBox>
      <StyledWrapper>
        <StyledTextWrapper>
          <StyledTitle>{filterTitle}</StyledTitle>
          <StyledDesc>{filterDescription}</StyledDesc>
          <StyledDataItems>
            {isMobile ? (
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={12}
                slidesPerView="auto"
                freeMode
                centeredSlides
                slideToClickedSlide
              >
                {FILTER_TYPES.map((section, index) => (
                  <SwiperSlide key={index}>
                    <StyledFilterRow key={index}>
                      {section.icon} <Typography>{filters[index]}</Typography>
                    </StyledFilterRow>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <Stack>
                {FILTER_TYPES.map((section, index) => (
                  <StyledFilterRow
                    key={index}
                    className={index === activeIndex ? "active" : ""}
                    onMouseOver={() => handleClick(index)}
                  >
                    {section.icon} <Typography>{filters[index]}</Typography>
                  </StyledFilterRow>
                ))}
              </Stack>
            )}
          </StyledDataItems>
        </StyledTextWrapper>
        <StyledImagesWrapper>
          <Swiper
            onSwiper={(swiper) => {
              setImageSwiper(swiper);
            }}
            effect="fade"
            slidesPerView={1}
            centeredSlides
            allowTouchMove={false}
            modules={[EffectFade]}
          >
            {FILTER_TYPES.map((section, index) => (
              <SwiperSlide key={index}>
                <StyledImage src={section.imageUrl} alt={section.title} width={800} height={800} />
              </SwiperSlide>
            ))}
          </Swiper>
        </StyledImagesWrapper>
      </StyledWrapper>
    </StyledBox>
  );
}

export default Filters;
