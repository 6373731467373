import { Fade, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import BetIconsRandomPosition from "./BetIconsRandomPosition";

interface IProps {
  randomIconImages: string[];
}

function RandomBetSitesImages({ randomIconImages }: IProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [imageCount, setImageCount] = useState(0);
  const stackRef = useRef(null);

  const randomIconImagesWithCount = useMemo(
    () => randomIconImages.slice(0, isMobile ? 3 : Math.min(imageCount, 10)),
    [isMobile, imageCount, randomIconImages],
  );

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0];
      if (entry) {
        const { width } = entry.contentRect;
        const count = Math.floor(width / 100);
        setImageCount(count);
      }
    };

    const observer = new ResizeObserver(handleResize);
    if (stackRef.current) {
      observer.observe(stackRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Fade in>
      <Stack direction="row" justifyContent="space-between" ref={stackRef}>
        {randomIconImagesWithCount.map((iconImage, i) => (
          <BetIconsRandomPosition key={iconImage} src={`/betsites/icons/${iconImage}`} index={i} />
        ))}
      </Stack>
    </Fade>
  );
}

export default RandomBetSitesImages;
